// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contribution-js": () => import("./../../../src/pages/contribution.js" /* webpackChunkName: "component---src-pages-contribution-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-report-js": () => import("./../../../src/pages/report.js" /* webpackChunkName: "component---src-pages-report-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-tab-js": () => import("./../../../src/pages/tab.js" /* webpackChunkName: "component---src-pages-tab-js" */),
  "component---src-pages-thesis-js": () => import("./../../../src/pages/thesis.js" /* webpackChunkName: "component---src-pages-thesis-js" */),
  "component---src-template-blog-single-js": () => import("./../../../src/template/blog-single.js" /* webpackChunkName: "component---src-template-blog-single-js" */),
  "component---src-template-news-single-js": () => import("./../../../src/template/news-single.js" /* webpackChunkName: "component---src-template-news-single-js" */),
  "component---src-template-product-single-js": () => import("./../../../src/template/product-single.js" /* webpackChunkName: "component---src-template-product-single-js" */)
}

